<template>
	<div>
		<div v-if="!value || isEditEditor" class="position-relative">
			<ValidationProvider :ref="`editor-${id}`" :name="`editor-${id}`">
				<textarea
					:id="`editor-${id}`"
					v-model.trim="editor"
					rows="2"
					class="form-control pr-25 remove-resize custom-textarea textarea-editor"
					:maxlength="ORDER_RULE.EDITOR"
				/>
			</ValidationProvider>
			<span class="prefix-input icon__check" @click="save">
				<font-awesome-icon :icon="['fas', 'check']" />
			</span>
		</div>
		<div v-else class="cursor-pointer editor" @click="editEditor">{{ value }}</div>
	</div>
</template>
<script>
import { ORDER_RULE } from "@/shared/plugins/constants"
export default {
	name: "OrderEditor",
	props: {
		index: {
			type: Number,
			default: null,
		},
		id: {
			type: [Number, String],
			default: null,
		},
		value: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			editor: null,
			ORDER_RULE,
			isEditEditor: false,
		}
	},
	watch: {
		value(val) {
			if (!val) return
			this.isEditEditor = false
		},
	},
	methods: {
		save() {
			const editorhange = this.$refs[`editor-${this.id}`].flags.dirty

			if (!editorhange || this.editor === this.value) return (this.isEditEditor = false)

			const params = {
				orderId: this.id,
				editor: this.editor,
			}
			this.$emit("save", params)
			this.isEditEditor = false
		},
		editEditor() {
			this.editor = this.value
			this.isEditEditor = true
		},
	},
}
</script>
<style lang="scss" scope>
.icon__check {
	position: absolute;
	right: 16px;
	top: 0;
	cursor: pointer;
	height: 100%;
	display: flex;
	align-items: center;
}
.pr-25 {
	padding-right: 22px;
	padding-left: 5px;
}

.custom-textarea {
	height: 45px;
}

.textarea-editor {
	min-width: 175px;
}

.cursor-pointer {
	cursor: pointer;
}
</style>
